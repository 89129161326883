import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CreateIrrigationForm from '@/components/forms/CreateIrrigationForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const CreateIrrigation: FC = () => {
  const { t } = useTranslation('translation');

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t('scheduleIrrigation')}</CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <CreateIrrigationForm />
      </CardContent>
    </Card>
  );
};
export default CreateIrrigation;
