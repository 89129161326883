import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { setDevicesForEdit } from '@/redux/device/device.slice.ts';
import {
  GetDeviceByIdForEditResponse,
  GetDevicesForEditResponse,
  UpdateDeviceForUserData,
} from '@/redux/device/device.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getDevicesByParcelId = createAsyncThunk<
  GetDevicesForEditResponse,
  string
>('user/devices-by-parcel-id', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetDevicesForEditResponse>(
      `/user/device/get-devices-for-parcel?parcelId=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setDevicesForEdit(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getFertDeviceById = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  string
>('user/fert-by-id', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.get<GetDeviceByIdForEditResponse>(
      `/user/device/get-fert-by-id?id=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getStartDeviceById = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  string
>('user/start-by-id', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.get<GetDeviceByIdForEditResponse>(
      `/user/device/get-start-by-id?id=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getSensDeviceById = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  string
>('user/sens-by-id', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.get<GetDeviceByIdForEditResponse>(
      `/user/device/get-sens-by-id?id=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const updateFertDevice = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  UpdateDeviceForUserData
>('user/update-fert', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.patch<GetDeviceByIdForEditResponse>(
      `/user/device/update-fert`,
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const updateStartDevice = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  UpdateDeviceForUserData
>('user/update-start', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.patch<GetDeviceByIdForEditResponse>(
      `/user/device/update-start`,
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const updateSensDevice = createAsyncThunk<
  GetDeviceByIdForEditResponse,
  UpdateDeviceForUserData
>('user/update-sens', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.patch<GetDeviceByIdForEditResponse>(
      `/user/device/update-sens`,
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  getDevicesByParcelId,
  getStartDeviceById,
  getSensDeviceById,
  getFertDeviceById,
  updateFertDevice,
  updateSensDevice,
  updateStartDevice,
};
