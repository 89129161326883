import { FC } from 'react';

import SavedProgramsSection from '@/components/layout/home/SavedProgramsSection.tsx';
import ScheduledIrrigationSection from '@/components/layout/home/ScheduledIrrigationSection.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const IrrigationPage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <SelectedParcelHeader showNewIcon />
          <ScheduledIrrigationSection />
          <SavedProgramsSection />
        </div>
      </div>
    </div>
  );
};

export default IrrigationPage;
