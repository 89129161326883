import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ResetPasswordForm from '@/components/forms/ResetPasswordForm.tsx';
import LoginPageLeftSection from '@/components/layout/login/LoginPageLeftSection.tsx';
import { config } from '@/config/config.ts';
import { useQuery } from '@/hooks/global/useQuery.ts';

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    if (query.get('isMobile') && query.get('token')) {
      const nativeAppName = config.mobileApp.name;
      window.location.replace(
        `${nativeAppName}/reset-password?token=${query.get('token')}`
      );
    }
  }, [query]);

  return (
    <div className="h-screen overflow-hidden">
      <div className="bg-[url('/loginBg.jpg')] fixed top-0 left-0 w-full h-screen bg-cover bg-center bg-no-repeat -z-10 blur-[3px]" />
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px] rounded-lg overflow-hidden">
          <div className="flex items-center justify-center py-12 bg-white">
            <div className="mx-auto grid w-[350px] gap-6">
              <div className="grid gap-2 text-center">
                <h1 className="text-3xl font-bold">{t('changePassword')}</h1>
                <p className="text-balance text-muted-foreground">
                  {t('changePasswordText')}
                </p>
              </div>
              <ResetPasswordForm />
            </div>
          </div>
          <div
            className="relative hidden bg-muted lg:block h-full bg-cover bg-center xl:w-[600px] lg:w-[500px]"
            style={{ backgroundImage: `url('/loginBg.png')` }}
          >
            <div className="flex flex-col absolute justify-center items-start inset-0 text-white font-bold">
              <LoginPageLeftSection />
            </div>
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default ResetPasswordPage;
