import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import { Form } from '@/components/ui/form.tsx';
import i18n from '@/i18n.ts';
import { EditDeviceFormData } from '@/pages/EditDevicePage.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import {
  updateFertDevice,
  updateSensDevice,
  updateStartDevice,
} from '@/redux/device/device.actions.ts';
import {
  DeviceType,
  GetDeviceByIdForEditResponse,
  UpdateDeviceForUserData,
} from '@/redux/device/device.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';
import { createEditDeviceStartData } from '@/utils/devices.ts';
import { createEditDeviceSchema } from '@/zod/types.ts';

type EditDeviceFormProps = {
  existingDevice: EditDeviceFormData;
};

const EditDeviceForm: FC<EditDeviceFormProps> = ({ existingDevice }) => {
  const parcelId = useAppSelector(getSelectedParcelId);
  const locale = i18n.language;
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);
  const { t } = useTranslation('translation');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateSchema = useCallback(() => createEditDeviceSchema(), [locale]);

  const editForm = useForm({
    resolver: zodResolver(generateSchema()),
    defaultValues: { ...createEditDeviceStartData() },
  });

  const handleErrorResponse = (response: GetDeviceByIdForEditResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const updateFert = async (data: UpdateDeviceForUserData) => {
    // @ts-ignore
    const response = await dispatch(updateFertDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success(t('deviceUpdated'));
    onGiveUpClick();
  };

  const updateStart = async (data: UpdateDeviceForUserData) => {
    // @ts-ignore
    const response = await dispatch(updateStartDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success(t('deviceUpdated'));
    onGiveUpClick();
  };

  const updateSens = async (data: UpdateDeviceForUserData) => {
    // @ts-ignore
    const response = await dispatch(updateSensDevice(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success(t('deviceUpdated'));
    onGiveUpClick();
  };

  const deviceTypeMapper = {
    [DeviceType.FERT]: updateFert,
    [DeviceType.START]: updateStart,
    [DeviceType.SENS]: updateSens,
  };

  const onSubmit = async (data: EditDeviceFormData) => {
    const dataToSend: UpdateDeviceForUserData = {
      id: data.id,
      name: data.name,
      color: data.color,
      parcelId,
    };

    await deviceTypeMapper[existingDevice.type](dataToSend);
  };

  useEffect(() => {
    if (!existingDevice.id) {
      return;
    }

    editForm.setValue('id', existingDevice.id);
    editForm.setValue('name', existingDevice.name);
    editForm.setValue('type', existingDevice.type);
    editForm.setValue('color', existingDevice.color);
    editForm.setValue('numberOfZones', existingDevice.numberOfZones);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingDevice]);

  useEffect(() => {
    const resetSchemaAndTriggerValidation = async () => {
      await editForm.trigger();
    };

    if (existingDevice.id) {
      resetSchemaAndTriggerValidation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onParcelChange = useCallback(() => onGiveUpClick(), [parcelId]);

  useEffect(() => {
    if (existingDevice.id && parcelId) {
      onParcelChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <Form
      reset={editForm.reset}
      formState={editForm.formState}
      clearErrors={editForm.clearErrors}
      control={editForm.control}
      getFieldState={editForm.getFieldState}
      getValues={editForm.getValues}
      handleSubmit={editForm.handleSubmit}
      register={editForm.register}
      resetField={editForm.resetField}
      setError={editForm.setError}
      setFocus={editForm.setFocus}
      setValue={editForm.setValue}
      trigger={editForm.trigger}
      unregister={editForm.unregister}
      watch={editForm.watch}
    >
      <form onSubmit={editForm.handleSubmit(onSubmit)}>
        <CustomFormInput
          customForm={editForm}
          name="name"
          labelText={t('deviceName')}
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-md:w-full md:w-[500px]"
          type="text"
        />
        <CustomFormInput
          customForm={editForm}
          name="type"
          labelText={t('type')}
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-md:w-full md:w-[500px]"
          type="text"
          disabled
        />
        <CustomFormInput
          customForm={editForm}
          name="numberOfZones"
          labelText={t('deviceNumberOfZones')}
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-md:w-full md:w-[500px]"
          type="text"
          disabled
        />
        <CustomFormInput
          customForm={editForm}
          name="color"
          labelText={t('color')}
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-md:w-full"
          divStyle="w-[50px]"
          type="color"
        />
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText={t('changeDevice')}
        />
      </form>
    </Form>
  );
};

export default EditDeviceForm;
