import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type ParcelValveFormProps = {
  form: UseFormReturn<any>;
};

const ParcelValveForm: FC<ParcelValveFormProps> = ({ form }) => {
  const { t } = useTranslation('translation');

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="durationOfTheActiveValve"
        labelText={t('durationOfActiveValve')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px] flex flex-row items-center justify-start gap-2"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        showInfoIcon
        infoText={t('durationOfActiveValveDesc')}
      />
      <CustomFormInput
        customForm={form}
        name="timeBetweenValves"
        labelText={t('timeBetweenValves')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px] flex flex-row items-center justify-start gap-2"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        showInfoIcon
        infoText={t('timeBetweenValvesDesc')}
      />
    </>
  );
};

export default ParcelValveForm;
