import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { Label } from '@/components/ui/label.tsx';
import i18n from '@/i18n.ts';
import { userLogin } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createLoginSchema } from '@/zod/types.ts';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginForm: FC = () => {
  const locale = i18n.language;
  const { t } = useTranslation('translation');

  const initialRender = useRef(true);
  const hasTriggered = useRef(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateSchema = useCallback(() => createLoginSchema(), [locale]);

  const loginForm = useForm<LoginFormData>({
    resolver: zodResolver(generateSchema()),
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onSubmit = async (data: LoginFormData) => {
    // @ts-ignore
    const response = await dispatch(userLogin(data)).unwrap();

    if (!response.success) {
      loginForm.setError('password', { message: t('wrongCredentials' as any) });
      return;
    }

    router('/');
  };

  useEffect(() => {
    const resetSchemaAndTriggerValidation = async () => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
      await loginForm.trigger();
    };

    const errors = Object.keys(loginForm.formState.errors).length;
    if (hasTriggered.current && errors) {
      resetSchemaAndTriggerValidation();
      return;
    }

    if (
      hasTriggered.current &&
      !loginForm.getValues('email') &&
      !loginForm.getValues('password')
    ) {
      return;
    }

    resetSchemaAndTriggerValidation();
    hasTriggered.current = true;
  }, [locale, loginForm]);

  return (
    <Form
      reset={loginForm.reset}
      formState={loginForm.formState}
      clearErrors={loginForm.clearErrors}
      control={loginForm.control}
      getFieldState={loginForm.getFieldState}
      getValues={loginForm.getValues}
      handleSubmit={loginForm.handleSubmit}
      register={loginForm.register}
      resetField={loginForm.resetField}
      setError={loginForm.setError}
      setFocus={loginForm.setFocus}
      setValue={loginForm.setValue}
      trigger={loginForm.trigger}
      unregister={loginForm.unregister}
      watch={loginForm.watch}
    >
      <form
        className="grid gap-4 px-2"
        onSubmit={loginForm.handleSubmit(onSubmit)}
      >
        <div className="grid gap-2">
          <CustomFormInput
            customForm={loginForm}
            name="email"
            labelText={t('email')}
            formItemStyle="flex flex-col max-md:flex-col w-full"
            labelStyle=""
            divStyle="w-full"
            type="text"
            placeholder={t('email')}
            inputStyle="rounded-lg focus-visible:border-[#28a745] focus-visible:ring-0"
          />
        </div>
        <div className="grid gap-2">
          <div className="grid">
            <div className="flex items-center">
              <Label htmlFor="password">{t('password')}</Label>
              <Link
                to="/forgot-password"
                className="ml-auto inline-block text-sm underline hover:text-[#28a745]"
              >
                {t('forgotPassword')}
              </Link>
            </div>
            <CustomFormInput
              customForm={loginForm}
              name="password"
              labelText={t('password')}
              formItemStyle="flex flex-col max-md:flex-col w-full"
              labelStyle="hidden"
              divStyle="w-full"
              type="password"
              placeholder={t('password')}
              inputStyle="rounded-lg focus-visible:border-[#28a745] focus-visible:ring-0"
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            className="bg-[#28a745] px-2 py-3 text-white min-w-[100px] text-sm"
            type="submit"
          >
            {t('login')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginForm;
