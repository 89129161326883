import { z } from 'zod';

import i18n from '@/i18n.ts';

export const createLoginSchema = () => {
  return z.object({
    email: z.string().email({
      message: i18n.t('invalidEmail'),
    }),
    password: z.string().min(1, {
      message: i18n.t('mandatoryPassword'),
    }),
  });
};

export const createForgotPasswordSchema = () => {
  return z.object({
    email: z.string().email({
      message: i18n.t('invalidEmail'),
    }),
  });
};

export const createResetPasswordSchema = () => {
  return z
    .object({
      token: z.string().min(1, i18n.t('mandatoryToken')),
      password: z
        .string()
        .min(8, i18n.t('minPasswordLength'))
        .max(100, i18n.t('maxPasswordLength'))
        .refine((value) => /[a-z]/.test(value), i18n.t('passwordLowercase'))
        .refine((value) => /[A-Z]/.test(value), i18n.t('passwordUppercase'))
        .refine((value) => /\d/.test(value), i18n.t('passwordNumber'))
        .refine(
          (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
          i18n.t('passwordSpecialChar')
        ),
      confirmPassword: z.string().min(1, i18n.t('mandatoryConfirmPassword')),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: i18n.t('passwordMismatch'),
      path: ['confirmPassword'],
    });
};
export const createIrrigationFormSchema = () => {
  const basicIrrigationSchema = z.object({
    parcelId: z.string().min(1, i18n.t('mandatoryParcelId')),
    irrigationType: z.preprocess(
      (val) => (val === '' ? undefined : val),
      z.enum(['time', 'm3'], {
        required_error: i18n.t('mandatoryIrrigationType'),
      })
    ),
    dateAndTimeOfIrrigation: z.date().refine((date) => date >= new Date(), {
      message: i18n.t('dateOfIrrigationInPast'),
    }),
    workTimeOfIrrigation: z.coerce.number().optional(),
    shifts: z
      .array(
        z.object({
          shiftId: z.coerce.number(),
          zone: z.string(),
          waterLevel: z.coerce.number(),
        })
      )
      .optional(),
    mixerEnabled: z.boolean(),
    mixerStartDateTime: z.date().optional(),
    mixerWorkTime: z.coerce.number().optional(),
    supplementOneEnabled: z.boolean(),
    supplementOneDateTime: z.date().optional(),
    supplementOneWorkTime: z.coerce.number().optional(),
    pH: z.coerce.number().optional(),
    supplementTwoEnabled: z.boolean(),
    supplementTwoDateTime: z.date().optional(),
    supplementTwoWorkTime: z.coerce.number().optional(),
    supplementTwoEC: z.coerce.number().optional(),
    supplementThreeEnabled: z.boolean(),
    supplementThreeDateTime: z.date().optional(),
    supplementThreeWorkTime: z.coerce.number().optional(),
    supplementThreeEC: z.coerce.number().optional(),
    supplementFourEnabled: z.boolean(),
    supplementFourDateTime: z.date().optional(),
    supplementFourWorkTime: z.coerce.number().optional(),
    supplementFourEC: z.coerce.number().optional(),
  });

  const irrigationTypeRefinement = basicIrrigationSchema
    .refine(
      (data) => {
        return !(
          data.irrigationType === 'time' &&
          (!data.workTimeOfIrrigation || data.workTimeOfIrrigation <= 0)
        );
      },
      {
        path: ['workTimeOfIrrigation'],
        message: i18n.t('durationOfIrrigation'),
      }
    )
    .refine(
      (data) => {
        return !(
          data.irrigationType === 'm3' &&
          (!data.shifts || data.shifts.length === 0)
        );
      },
      {
        path: ['shifts'],
        message: i18n.t('mandatoryShift'),
      }
    );

  const mixerEnabledRefinement = irrigationTypeRefinement
    .refine(
      (data) => {
        if (data.mixerEnabled) {
          if (
            !data.mixerStartDateTime ||
            data.mixerStartDateTime < new Date()
          ) {
            return false;
          }
        }
        return true;
      },
      {
        path: ['mixerStartDateTime'],
        message: i18n.t('mixerDateInPast'),
      }
    )
    .refine(
      (data) => {
        if (data.mixerEnabled) {
          if (!data.mixerWorkTime || data.mixerWorkTime <= 0) {
            return false;
          }
        }
        return true;
      },
      {
        path: ['mixerWorkTime'],
        message: i18n.t('mixerWorkTime'),
      }
    );

  const supplementOneRequiredFieldsRefinement = mixerEnabledRefinement.refine(
    (data) => {
      if (data.supplementOneEnabled) {
        return (
          data.supplementOneDateTime && data.supplementOneWorkTime && data.pH
        );
      }
      return true;
    },
    {
      path: ['supplementOneDateTime'],
      message: i18n.t('supplementOneMandatoryFields'),
    }
  );

  const supplementOneDateTimeRefinement =
    supplementOneRequiredFieldsRefinement.refine(
      (data) => {
        if (data.supplementOneEnabled) {
          return (
            data.supplementOneDateTime >= data.dateAndTimeOfIrrigation &&
            data.supplementOneDateTime.getTime() +
              data.supplementOneWorkTime * 60000 <=
              data.dateAndTimeOfIrrigation.getTime() +
                data.workTimeOfIrrigation * 60000
          );
        }
        return true;
      },
      {
        path: ['supplementOneDateTime'],
        message: i18n.t('supplementOneDateInPast'),
      }
    );

  const supplementTwoRequiredFieldsRefinement =
    supplementOneDateTimeRefinement.refine(
      (data) => {
        if (data.supplementTwoEnabled) {
          return (
            data.supplementTwoDateTime &&
            data.supplementTwoWorkTime &&
            data.supplementTwoEC
          );
        }
        return true;
      },
      {
        path: ['supplementTwoDateTime'],
        message: i18n.t('supplementTwoMandatoryFields'),
      }
    );

  const supplementTwoDateTimeRefinement =
    supplementTwoRequiredFieldsRefinement.refine(
      (data) => {
        if (data.supplementTwoEnabled) {
          return (
            data.supplementTwoDateTime >= data.dateAndTimeOfIrrigation &&
            data.supplementTwoDateTime.getTime() +
              data.supplementTwoWorkTime * 60000 <=
              data.dateAndTimeOfIrrigation.getTime() +
                data.workTimeOfIrrigation * 60000
          );
        }
        return true;
      },
      {
        path: ['supplementTwoDateTime'],
        message: i18n.t('supplementTwoDateInPast'),
      }
    );

  const supplementThreeRequiredFieldsRefinement =
    supplementTwoDateTimeRefinement.refine(
      (data) => {
        if (data.supplementThreeEnabled) {
          return (
            data.supplementThreeDateTime &&
            data.supplementThreeWorkTime &&
            data.supplementThreeEC
          );
        }
        return true;
      },
      {
        path: ['supplementThreeDateTime'],
        message: i18n.t('supplementThreeMandatoryFields'),
      }
    );

  const supplementThreeDateTimeRefinement =
    supplementThreeRequiredFieldsRefinement.refine(
      (data) => {
        if (data.supplementThreeEnabled) {
          return (
            data.supplementThreeDateTime >= data.dateAndTimeOfIrrigation &&
            data.supplementThreeDateTime.getTime() +
              data.supplementThreeWorkTime * 60000 <=
              data.dateAndTimeOfIrrigation.getTime() +
                data.workTimeOfIrrigation * 60000
          );
        }
        return true;
      },
      {
        path: ['supplementThreeDateTime'],
        message: i18n.t('supplementThreeDateInPast'),
      }
    );

  const supplementFourRequiredFieldsRefinement =
    supplementThreeDateTimeRefinement.refine(
      (data) => {
        if (data.supplementFourEnabled) {
          return (
            data.supplementFourDateTime &&
            data.supplementFourWorkTime &&
            data.supplementFourEC
          );
        }
        return true;
      },
      {
        path: ['supplementFourDateTime'],
        message: i18n.t('supplementFourMandatoryFields'),
      }
    );

  return supplementFourRequiredFieldsRefinement.refine(
    (data) => {
      if (data.supplementFourEnabled) {
        return (
          data.supplementFourDateTime >= data.dateAndTimeOfIrrigation &&
          data.supplementFourDateTime.getTime() +
            data.supplementFourWorkTime * 60000 <=
            data.dateAndTimeOfIrrigation.getTime() +
              data.workTimeOfIrrigation * 60000
        );
      }
      return true;
    },
    {
      path: ['supplementFourDateTime'],
      message: i18n.t('supplementFourDateInPast'),
    }
  );
};

export const createEditParcelSchema = () => {
  return z.object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: i18n.t('mandatoryParcelName'),
    }),
    numberOfZones: z.number().refine((val) => val > 0, {
      message: i18n.t('numberOfZonesValidation'),
    }),
    place: z.string().min(1, {
      message: i18n.t('mandatoryPlace'),
    }),
    country: z.string().min(1, {
      message: i18n.t('mandatoryCountry'),
    }),
    weatherForecast: z.string().min(1, {
      message: i18n.t('mandatoryWeatherForecast'),
    }),
    durationOfTheActiveValve: z
      .string()
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: i18n.t('durationOfTheActiveValveValidation'),
      }),
    timeBetweenValves: z
      .string()
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: i18n.t('timeBetweenValvesValidation'),
      }),
  });
};

export const createEditDeviceSchema = () => {
  return z.object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: i18n.t('mandatoryDeviceName'),
    }),
    color: z.string().min(1, {
      message: i18n.t('colorMandatory'),
    }),
  });
};
