import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import {
  FieldArray,
  FieldArrayMethodProps,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormReturn,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { IrrigationFormData } from '@/components/forms/CreateIrrigationForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getSelectedParcelNumberOfZones } from '@/redux/parcel/parcel.selectors.ts';
import { getZonesForNumberOfZones } from '@/utils/irrigation.utils.ts';

type ShiftsFormSectionProps = {
  form: UseFormReturn<any>;
  fields: FieldArrayWithId<IrrigationFormData, 'shifts', 'id'>[];
  append: (
    value:
      | FieldArray<IrrigationFormData, 'shifts'>
      | FieldArray<IrrigationFormData, 'shifts'>[],
    options?: FieldArrayMethodProps
  ) => void;
  remove: UseFieldArrayRemove;
};

type ShiftFormProps = {
  form: UseFormReturn;
  field: FieldArrayWithId<IrrigationFormData, 'shifts', 'id'>;
  index: number;
  zonesForDropdown: DropDownItems[];
  selectedZones: { shift: number; zone: string }[];
  onZoneSelect: (zone: string, shiftId: number) => void;
  setSelectedZones: (value: ((prevState: any[]) => any[]) | any[]) => void;
  remove: UseFieldArrayRemove;
};

const generateId = (
  elements: { shiftId: number; zone: string; waterLevel: number }[] | null
): number => {
  if (!elements || !elements.length) {
    return 1;
  }

  const elementWithHighestId = elements.reduce((maxElement, currentElement) => {
    return currentElement.shiftId > maxElement.shiftId
      ? currentElement
      : maxElement;
  }, elements[0]);
  return elementWithHighestId.shiftId + 1;
};

const ShiftForm: FC<ShiftFormProps> = ({
  form,
  field,
  index,
  zonesForDropdown,
  selectedZones,
  onZoneSelect,
  setSelectedZones,
  remove,
}) => {
  const { t } = useTranslation('translation');

  const onZoneClickHandler = (selectedZone: string) => {
    onZoneSelect(selectedZone, field.shiftId);
  };

  const onRemoveZoneClick = () => {
    setSelectedZones((prev) =>
      prev.filter((prevZone) => prevZone.shift !== field.shiftId)
    );
    remove(index);
  };

  const itemsForDropdown = zonesForDropdown.filter((zone) => {
    const inSelected = selectedZones.filter(
      (selected) =>
        selected.shift !== field.shiftId && selected.zone === zone.value
    );

    return !inSelected.length;
  });

  return (
    <div className="flex flex-col">
      <span className="text-xl">
        {t('shift')} {index + 1}
      </span>
      <div className="flex flex-row max-md:flex-col gap-5 pt-3">
        <CustomFormDropDown
          customForm={form}
          name={`shifts.${index}.zone`}
          labelText={t('zone')}
          formItemStyle="w-[500px] flex flex-col max-md:w-full"
          labelStyle="max-md:w-full"
          items={itemsForDropdown}
          onChangeFunction={onZoneClickHandler}
        />
        <CustomFormInput
          customForm={form}
          name={`shifts.${index}.waterLevel`}
          labelText="m3"
          formItemStyle="flex flex-col w-[500px] max-md:w-full"
          labelStyle="w-full"
          divStyle="w-full"
          type="number"
          inputStyle="py-[22px]"
        />
        <div className="w-[20px] items-end justify-center flex">
          <Button size="icon" onClick={onRemoveZoneClick}>
            <FontAwesomeIcon
              icon={faTrash}
              color="red"
              className="w-full h-4"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

const ShiftsFormSection: FC<ShiftsFormSectionProps> = ({
  form,
  fields,
  append,
  remove,
}) => {
  const { t } = useTranslation('translation');
  const parcelNumberOfZones = useAppSelector(getSelectedParcelNumberOfZones);
  const zonesForDropdown = getZonesForNumberOfZones(parcelNumberOfZones);
  const [selectedZones, setSelectedZones] = useState([]);

  const onZoneSelect = (selectedZone: string, shiftId: number) => {
    setSelectedZones((prev) => [
      ...prev.filter((prevZone) => prevZone.shift !== shiftId),
      { zone: selectedZone, shift: shiftId },
    ]);
  };

  const onAddShiftClick = () => {
    if (fields.length === parcelNumberOfZones) {
      toast.info(t('maxNumberOfShifts'));
      return;
    }

    append({ shiftId: generateId(fields), zone: '', waterLevel: 0 });
  };

  return (
    <div className="flex flex-col gap-5 pb-5 max-md:py-5 pt-2">
      {fields.map((field, index) => (
        <ShiftForm
          key={`shift-for-irrigation-creation-${field.id}`}
          form={form}
          field={field}
          index={index}
          zonesForDropdown={zonesForDropdown}
          selectedZones={selectedZones}
          onZoneSelect={onZoneSelect}
          setSelectedZones={setSelectedZones}
          remove={remove}
        />
      ))}
      <Button
        className="bg-[#33b35a] py-3 px-2 text-white w-[120px]"
        type="button"
        onClick={onAddShiftClick}
      >
        {t('addShift')}
      </Button>
    </div>
  );
};

export default ShiftsFormSection;
