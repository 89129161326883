import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import SelectUserParcelForm from '@/components/forms/SelectUserParcelForm.tsx';
import { Card, CardHeader, CardTitle } from '@/components/ui/card.tsx';

type SelectParcelHeaderProps = {
  showNewIcon: boolean;
};

const SelectedParcelHeader: FC<SelectParcelHeaderProps> = ({ showNewIcon }) => {
  const { t } = useTranslation('translation');

  return (
    <Card>
      <CardHeader className="p-0">
        <CardTitle className="flex items-center w-full py-3">
          <nav className="flex items-center justify-between bg-white w-full py-5 px-5 gap-5 text-center max-md:flex-col max-md:items-start">
            <SelectUserParcelForm />
            {showNewIcon ? (
              <AddNewIcon
                link="/irrigation/create"
                text={t('scheduleIrrigation')}
              />
            ) : null}
          </nav>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};

export default SelectedParcelHeader;
