import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormDateTimePicker from '@/components/controls/CustomFormDateTimePicker.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';

type SupplementFormProps = {
  form: UseFormReturn<any>;
  index: number;
  enableName: string;
  startDateName: string;
  workTimeName: string;
  inputFieldName: string;
  inputFieldLabelName: string;
};

const SupplementForm: FC<SupplementFormProps> = ({
  form,
  index,
  startDateName,
  enableName,
  workTimeName,
  inputFieldName,
  inputFieldLabelName,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className="pb-5 pt-5 text-sm">
      <CustomFormSwitch
        customForm={form}
        name={enableName}
        labelText={`${t('supplement')} ${index}`}
        formItemStyle="flex flex-col gap-2"
        labelStyle="text-sm w-[200px]"
      />
      {form.getValues(enableName) && (
        <div className="flex flex-row max-md:flex-col gap-2 pt-3 w-full mt-2">
          <CustomFormDateTimePicker
            form={form}
            name={startDateName}
            labelText={t('startDateTime')}
            formItemStyle="flex flex-col w-[500px] max-md:w-full"
            labelStyle="max-md:w-full"
            buttonStyle="justify-start text-left font-normal w-full bg-white py-3 px-2 flex"
          />
          <div className="flex flex-row w-[50%] max-md:w-full gap-2">
            <CustomFormInput
              customForm={form}
              name={workTimeName}
              labelText={t('timeInMinutes')}
              formItemStyle="flex flex-col w-[246px] max-md:w-[50%]"
              inputStyle="px-2 py-[22px]"
              labelStyle="w-full"
              divStyle="w-full"
              type="number"
            />
            <CustomFormInput
              customForm={form}
              name={inputFieldName}
              labelText={inputFieldLabelName}
              formItemStyle="flex flex-col w-[246px] max-md:justify-end max-md:w-[50%]"
              inputStyle="px-2 py-[22px]"
              labelStyle="w-full"
              divStyle="w-full"
              type="number"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SupplementForm;
