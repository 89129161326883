import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditDeviceForm from '@/components/forms/EditDeviceForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  getFertDeviceById,
  getSensDeviceById,
  getStartDeviceById,
} from '@/redux/device/device.actions.ts';
import {
  DeviceType,
  GetDeviceByIdForEditResponse,
} from '@/redux/device/device.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

export type EditDeviceFormData = {
  id: string;
  name: string;
  type: DeviceType;
  color: string;
  numberOfZones: number;
};

const EditDevicePage: FC = () => {
  const { t } = useTranslation('translation');

  const params = useParams();
  const [deviceForEdit, setDeviceForEdit] = useState<EditDeviceFormData>({
    id: '',
    name: '',
    color: '#ffffff',
    type: DeviceType.FERT,
    numberOfZones: 0,
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: GetDeviceByIdForEditResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const fetchFertDevice = async () => {
    // @ts-ignore
    const response = await dispatch(getFertDeviceById(params.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setDeviceForEdit(response.content);
  };

  const fetchStartDevice = async () => {
    // @ts-ignore
    const response = await dispatch(getStartDeviceById(params.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setDeviceForEdit(response.content);
  };

  const fetchSensDevice = async () => {
    // @ts-ignore
    const response = await dispatch(getSensDeviceById(params.id)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    setDeviceForEdit(response.content);
  };

  const typeMapper = {
    [DeviceType.FERT]: fetchFertDevice,
    [DeviceType.START]: fetchStartDevice,
    [DeviceType.SENS]: fetchSensDevice,
  };

  useEffect(() => {
    const fetchDevice = async () => {
      await typeMapper[params.type]();
    };

    fetchDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.type]);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 sm:px-6 p-4 sm:py-0 md:gap-8">
          <SelectedParcelHeader showNewIcon />
          <Card x-chunk="edit-parcel-cart">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>{t('deviceSettings')}</CardTitle>
            </CardHeader>
            <CardContent>
              <EditDeviceForm existingDevice={deviceForEdit} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EditDevicePage;
