import {
  BatteryFull,
  BatteryLow,
  BatteryMedium,
  SignalHigh,
  SignalLow,
  SignalMedium,
} from 'lucide-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { DeviceForUserEdit } from '@/redux/device/device.types.ts';

type BatteryStatusProps = {
  batteryLevel: number;
};

type ConnectionLevelProps = {
  connectionLevel: number;
};

type DeviceTableRowProsp = {
  device: DeviceForUserEdit;
};

const BatteryStatus: FC<BatteryStatusProps> = ({ batteryLevel }) => {
  if (batteryLevel < 0.3) {
    return <BatteryLow color="red" />;
  }

  if (batteryLevel < 0.7) {
    return <BatteryMedium color="orange" />;
  }

  return <BatteryFull color="green" />;
};

const ConnectionStatus: FC<ConnectionLevelProps> = ({ connectionLevel }) => {
  if (connectionLevel < 0.3) {
    return <SignalLow color="red" />;
  }

  if (connectionLevel < 0.7) {
    return <SignalMedium color="orange" />;
  }

  return <SignalHigh color="green" />;
};

const DeviceTableRow: FC<DeviceTableRowProsp> = ({ device }) => {
  const router = useNavigate();

  const onEditUserClick = (deviceId: string) => {
    router(`/change-device/${device.type}/${deviceId}`);
  };

  return (
    <TableRow>
      <TableCell
        className="cursor-pointer"
        onClick={() => onEditUserClick(device.id)}
      >
        {device.name}
      </TableCell>
      <TableCell className="">
        <BatteryStatus batteryLevel={device.batteryLevel} />
      </TableCell>
      <TableCell className="">
        <ConnectionStatus connectionLevel={device.connectionLevel} />
      </TableCell>
    </TableRow>
  );
};

export default DeviceTableRow;
