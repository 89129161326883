import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import i18n from '../../i18n.ts';

import { BreadcrumbLinkType } from '@/redux/breadcrumbs/breadcrumbs.types.ts';

const breadcrumbsSliceInitialState = {
  breadcrumbsList:
    sessionStorage.getItem('breadcrumbs') &&
    sessionStorage.getItem('breadcrumbs').length
      ? JSON.parse(sessionStorage.getItem('breadcrumbs'))
      : [
          {
            link: '/',
            linkText: i18n.t('home'),
            showSeparator: false,
            localeId: 'home',
          },
        ],
};

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbsSlice',
  initialState: breadcrumbsSliceInitialState,
  reducers: {
    routeToPage: (state, action: PayloadAction<BreadcrumbLinkType>) => {
      const indexOfElement = state.breadcrumbsList.findIndex(
        (breadcrumb) => breadcrumb.link === action.payload.link
      );

      const containsLink = state.breadcrumbsList.filter(
        (breadcrumb) => breadcrumb.link === action.payload.link
      );
      if (!containsLink.length) {
        state.breadcrumbsList[state.breadcrumbsList.length - 1].showSeparator =
          true;
        state.breadcrumbsList.push(action.payload);
        sessionStorage.setItem(
          'breadcrumbs',
          JSON.stringify(state.breadcrumbsList)
        );
        return;
      }

      state.breadcrumbsList = state.breadcrumbsList.slice(
        0,
        indexOfElement + 1
      );
      state.breadcrumbsList[state.breadcrumbsList.length - 1].showSeparator =
        false;
      sessionStorage.setItem(
        'breadcrumbs',
        JSON.stringify(state.breadcrumbsList)
      );
    },
    resetBreadcrumbs: (state) => {
      state.breadcrumbsList = [
        {
          link: '/',
          linkText: i18n.t('home'),
          showSeparator: false,
          localeId: 'home',
        },
      ];
      sessionStorage.setItem(
        'breadcrumbs',
        JSON.stringify(state.breadcrumbsList)
      );
    },
    changeLocaleForBreadcrumbs: (state) => {
      state.breadcrumbsList = state.breadcrumbsList.map((breadcrumb) => {
        return {
          ...breadcrumb,
          linkText: i18n.t(breadcrumb.localeId),
        };
      });
      sessionStorage.setItem(
        'breadcrumbs',
        JSON.stringify(state.breadcrumbsList)
      );
    },
  },
});

export const { routeToPage, resetBreadcrumbs, changeLocaleForBreadcrumbs } =
  breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
