import './App.css';

import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthenticatedRoutes from '@/components/router/AuthenticatedRoutes.tsx';
import NotAuthenticatedRoutes from '@/components/router/NotAuthenticatedRoutes.tsx';
import LocaleMiddleware from '@/middleware/LocaleMiddleware.tsx';
import RoutesMiddleware from '@/middleware/RoutesMiddleware.tsx';
import CreateIrrigationPage from '@/pages/CreateIrrigationPage.tsx';
import DevicesForEditPage from '@/pages/DevicesForEditPage.tsx';
import EditDevicePage from '@/pages/EditDevicePage.tsx';
import EditParcelPage from '@/pages/EditParcelPage.tsx';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage.tsx';
import HomePage from '@/pages/HomePage.tsx';
import IrrigationPage from '@/pages/IrrigationPage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import NotFoundPage from '@/pages/NotFoundPage.tsx';
import ResetPasswordPage from '@/pages/ResetPasswordPage.tsx';
import SettingsPage from '@/pages/SettingsPage.tsx';

function App() {
  return (
    <LocaleMiddleware>
      <ToastContainer />
      <BrowserRouter>
        <RoutesMiddleware>
          <Routes>
            <Route element={<AuthenticatedRoutes />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/irrigation" element={<IrrigationPage />} />
              <Route
                path="/irrigation/create"
                element={<CreateIrrigationPage />}
              />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/parcel-settings" element={<EditParcelPage />} />
              <Route path="/device-settings" element={<DevicesForEditPage />} />
              <Route
                path="/change-device/:type/:id"
                element={<EditDevicePage />}
              />
            </Route>
            <Route element={<NotAuthenticatedRoutes />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </RoutesMiddleware>
      </BrowserRouter>
    </LocaleMiddleware>
  );
}

export default App;
