import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { ForgotPasswordFormData } from '@/components/forms/ForgotPasswordForm.tsx';
import { LoginFormData } from '@/components/forms/LoginForm.tsx';
import { ResetPasswordFormData } from '@/components/forms/ResetPasswordForm.tsx';
import { login, logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { DefaultResponse } from '@/redux/auth/auth.types.ts';
import axiosServer from '@/services/axios.service.ts';

const userLogin = createAsyncThunk<DefaultResponse, LoginFormData>(
  'user/login',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post('/auth/user', reqData, {
        withCredentials: true,
      });
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      const { token } = axiosResponse.headers;
      localStorage.setItem('token', token);
      localStorage.setItem('name', axiosResponse.data.content.name);
      localStorage.setItem('id', axiosResponse.data.content.id);
      localStorage.setItem(
        'organizationId',
        axiosResponse.data.content.organizationId
      );
      dispatch(login());
      dispatch(
        setName({
          name: axiosResponse.data.content.name,
        })
      );
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const userForgotPassword = createAsyncThunk<
  DefaultResponse,
  ForgotPasswordFormData
>('user/forgot-password', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/user/forgot-password',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const userResetPassword = createAsyncThunk<
  DefaultResponse,
  ResetPasswordFormData
>('user/reset-password', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/user/reset-password',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const adminLoginUser = createAsyncThunk<
  DefaultResponse,
  { email: string; adminToken: string }
>('user/admin-login', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/admin/log-in-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    const { token } = axiosResponse.headers;
    localStorage.setItem('token', token);
    localStorage.setItem('name', axiosResponse.data.content.name);
    localStorage.setItem('id', axiosResponse.data.content.id);
    dispatch(login());
    dispatch(
      setName({
        name: axiosResponse.data.content.name,
      })
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const distributorLoginUser = createAsyncThunk<
  DefaultResponse,
  { email: string; distributorToken: string }
>('user/distributor-login', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/distributor/log-in-user',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    const { token } = axiosResponse.headers;
    localStorage.setItem('token', token);
    localStorage.setItem('name', axiosResponse.data.content.name);
    localStorage.setItem('id', axiosResponse.data.content.id);
    dispatch(login());
    dispatch(
      setName({
        name: axiosResponse.data.content.name,
      })
    );
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const userLogout = createAsyncThunk<DefaultResponse, void>(
  'user/logout',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post('/auth/user/logout', {});
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export {
  userLogin,
  userForgotPassword,
  userResetPassword,
  adminLoginUser,
  distributorLoginUser,
  userLogout,
};
