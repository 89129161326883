import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { FormDescription } from '@/components/ui/form.tsx';
import { cities, countries } from '@/utils/countryUtil.ts';

type ParcelInfoFormProps = {
  form: UseFormReturn<any>;
};

const ParcelInfoForm: FC<ParcelInfoFormProps> = ({ form }) => {
  const { t } = useTranslation('translation');

  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );

  const citiesComboboxContent: ComboboxItemType[] = cities.map((city) => {
    return {
      value: city,
      label: city,
    };
  });

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText={t('parcelName')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="numberOfZones"
        labelText={t('numberOfZones')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
      />
      <FormDescription className="px-2">
        {t('numberOfZonesDescription')}
      </FormDescription>
      <CustomFormInput
        customForm={form}
        name="place"
        labelText={t('place')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="country"
        labelText={t('country')}
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={countriesComboboxContent}
        placeholder={t('chooseCountry')}
      />
      <CustomFormCombobox
        customForm={form}
        name="weatherForecast"
        labelText={t('weatherForecast')}
        formItemStyle="flex flex-col w-full pb-5 mt-2 sm:mt-5 px-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full w-[500px] px-3 py-2 justify-between"
        items={citiesComboboxContent}
        placeholder={t('chooseWeatherForecast')}
      />
    </>
  );
};

export default ParcelInfoForm;
