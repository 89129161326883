import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useQuery } from '@/hooks/global/useQuery.ts';
import i18n from '@/i18n.ts';
import { userResetPassword } from '@/redux/auth/auth.actions.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createResetPasswordSchema } from '@/zod/types.ts';

export type ResetPasswordFormData = {
  token: string;
  password: string;
  confirmPassword: string;
};

const ResetPasswordForm: FC = () => {
  const locale = i18n.language;
  const { t } = useTranslation('translation');
  const query = useQuery();

  const initialRender = useRef(true);
  const hasTriggered = useRef(false);
  const generateSchema = useCallback(
    () => createResetPasswordSchema(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );
  const resetPasswordForm = useForm<ResetPasswordFormData>({
    resolver: zodResolver(generateSchema()),
    defaultValues: {
      token: query.get('token'),
      password: '',
      confirmPassword: '',
    },
  });

  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onSubmit = async (data: ResetPasswordFormData) => {
    // @ts-ignore
    const response = await dispatch(userResetPassword(data)).unwrap();

    if (!response.success) {
      toast.error(response.error.message);
      return;
    }

    router('/login');
  };

  useEffect(() => {
    const resetSchemaAndTriggerValidation = async () => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
      await resetPasswordForm.trigger();
    };

    if (
      hasTriggered.current &&
      !resetPasswordForm.getValues('password') &&
      !resetPasswordForm.getValues('confirmPassword')
    ) {
      resetPasswordForm.clearErrors();
      return;
    }

    resetSchemaAndTriggerValidation();
    hasTriggered.current = true;
  }, [locale, resetPasswordForm]);

  return (
    <Form
      reset={resetPasswordForm.reset}
      formState={resetPasswordForm.formState}
      clearErrors={resetPasswordForm.clearErrors}
      control={resetPasswordForm.control}
      getFieldState={resetPasswordForm.getFieldState}
      getValues={resetPasswordForm.getValues}
      handleSubmit={resetPasswordForm.handleSubmit}
      register={resetPasswordForm.register}
      resetField={resetPasswordForm.resetField}
      setError={resetPasswordForm.setError}
      setFocus={resetPasswordForm.setFocus}
      setValue={resetPasswordForm.setValue}
      trigger={resetPasswordForm.trigger}
      unregister={resetPasswordForm.unregister}
      watch={resetPasswordForm.watch}
    >
      <form
        className="flex flex-col gap-6 pl-5 max-md:p-5 w-full"
        onSubmit={resetPasswordForm.handleSubmit(onSubmit)}
      >
        <CustomFormInput
          customForm={resetPasswordForm}
          name="password"
          labelText={t('password')}
          formItemStyle="flex flex-col max-md:flex-col w-full "
          labelStyle=""
          divStyle="w-full"
          type="password"
          placeholder={t('password')}
          inputStyle="rounded-lg focus-visible:border-[#28a745] focus-visible:ring-0"
        />
        <CustomFormInput
          customForm={resetPasswordForm}
          formItemStyle="flex flex-col max-md:flex-col w-full "
          labelStyle=""
          divStyle="w-full"
          inputStyle="rounded-lg focus-visible:border-[#28a745] focus-visible:ring-0"
          name="confirmPassword"
          labelText={t('confirmPassword')}
          type="password"
          placeholder={t('confirmPassword')}
        />
        <div>
          <Button className="bg-[#28a745] py-3 px-5 text-white" type="submit">
            {t('changePassword')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default ResetPasswordForm;
