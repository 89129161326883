import { Info } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card.tsx';
import { Input } from '@/components/ui/input.tsx';

type CustomHoverInputInfoProps = {
  infoText: string;
};

type CustomFormInputProps = {
  customForm: UseFormReturn<any>;
  name: string;
  labelText: string;
  type: string;
  formItemStyle?: string;
  labelStyle?: string;
  divStyle?: string;
  inputStyle?: string;
  placeholder?: string;
  showInfoIcon?: boolean;
  infoText?: string;
  disabled?: boolean;
};

const CustomHoverInputInfo: FC<CustomHoverInputInfoProps> = ({ infoText }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <Info />
      </HoverCardTrigger>
      <HoverCardContent>{infoText}</HoverCardContent>
    </HoverCard>
  );
};

const CustomFormInput: FC<CustomFormInputProps> = ({
  customForm,
  name,
  labelStyle,
  labelText,
  divStyle,
  formItemStyle,
  type,
  inputStyle,
  placeholder,
  showInfoIcon,
  infoText,
  disabled,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>
            {labelText}
            {showInfoIcon ? <CustomHoverInputInfo infoText={infoText} /> : null}
          </FormLabel>
          <div className={divStyle}>
            <FormControl className="w-full focus:outline-none">
              <Input
                {...field}
                type={type}
                className={inputStyle}
                placeholder={placeholder}
                disabled={disabled}
              />
            </FormControl>
            <FormMessage className="mt-2" />
          </div>
        </FormItem>
      )}
    />
  );
};

export default CustomFormInput;
