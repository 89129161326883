import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeviceForUserEdit } from '@/redux/device/device.types.ts';

const deviceSliceInitialState = {
  devicesForEdit: [],
};

const deviceSlice = createSlice({
  name: 'deviceSlice',
  initialState: deviceSliceInitialState,
  reducers: {
    setDevicesForEdit: (state, action: PayloadAction<DeviceForUserEdit[]>) => {
      state.devicesForEdit = action.payload || [];
    },
  },
});

export const { setDevicesForEdit } = deviceSlice.actions;
export default deviceSlice.reducer;
