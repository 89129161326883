import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import DeviceTableRow from '@/components/layout/device/DeviceTableRow.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import i18n from '@/i18n.ts';
import { getUserDevicesForEdit } from '@/redux/device/device.selectors.ts';
import { useAppSelector } from '@/redux/hooks.ts';

const DeviceTableSection: FC = () => {
  const devices = useAppSelector(getUserDevicesForEdit);
  const locale = i18n.language;
  const { t } = useTranslation('translation');
  const [devicesTableHeaders, setDevicesTableHeaders] = useState<
    TableHeaderType[]
  >([
    {
      id: 'name',
      text: t('deviceName'),
      className: '',
      isSortDisabled: true,
    },
    {
      id: 'batteryLevel',
      text: t('batteryLevel'),
      className: '',
      isSortDisabled: true,
    },
    {
      id: 'connectionLevel',
      text: t('connectionLevel'),
      className: '',
      isSortDisabled: true,
    },
  ]);

  useEffect(() => {
    setDevicesTableHeaders([
      {
        id: 'name',
        text: t('deviceName'),
        className: '',
        isSortDisabled: true,
      },
      {
        id: 'batteryLevel',
        text: t('batteryLevel'),
        className: '',
        isSortDisabled: true,
      },
      {
        id: 'connectionLevel',
        text: t('connectionLevel'),
        className: '',
        isSortDisabled: true,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <Card x-chunk="dashboard-devices">
      <CardHeader>
        <CardTitle>{t('chooseDevice')}</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <CustomTableHeader
            tableName="devices"
            tableHeaders={devicesTableHeaders}
          />
          <TableBody>
            {devices.map((device, index) => (
              <DeviceTableRow
                key={`device-table-data-device-${index + 1}`}
                device={device}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default DeviceTableSection;
