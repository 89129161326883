import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomFormDateTimePicker from '@/components/controls/CustomFormDateTimePicker.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';

type MixerFormProps = {
  form: UseFormReturn<any>;
};

const MixerForm: FC<MixerFormProps> = ({ form }) => {
  const { t } = useTranslation('translation');

  return (
    <div className="pb-5 pt-5 text-sm">
      <CustomFormSwitch
        customForm={form}
        name="mixerEnabled"
        labelText={t('mixer')}
        formItemStyle="flex flex-col gap-2"
        labelStyle="text-sm w-[20px]"
      />
      {form.getValues('mixerEnabled') && (
        <div className="flex flex-row max-md:flex-col gap-2 pt-3 w-full mt-2">
          <CustomFormDateTimePicker
            form={form}
            name="mixerStartDateTime"
            labelText={t('startDateTime')}
            formItemStyle="flex flex-col w-[500px] max-md:w-full"
            labelStyle="max-md:w-full"
            buttonStyle="justify-start text-left font-normal w-full bg-white py-3 px-2 flex"
          />
          <CustomFormInput
            customForm={form}
            name="mixerWorkTime"
            labelText={t('timeInMinutes')}
            formItemStyle="flex flex-col w-[500px] max-md:w-full"
            inputStyle="px-2 py-[22px]"
            labelStyle="w-full"
            divStyle="w-full"
            type="number"
          />
        </div>
      )}
    </div>
  );
};

export default MixerForm;
