import { UseFormReturn } from 'react-hook-form';

import { EditParcelFormData } from '@/components/forms/EditParcelForm.tsx';
import {
  AdditionalSensorValues,
  ParcelForUserEdit,
} from '@/redux/parcel/parcel.types.ts';

export const createEditParcelStartData = (): EditParcelFormData => {
  return {
    id: '',
    name: '',
    numberOfZones: 1,
    place: '',
    country: '',
    weatherForecast: 'Novi Sad',
    durationOfTheActiveValve: '0',
    timeBetweenValves: '0',
  };
};

export const castStringToNumber = (form: UseFormReturn<any>) => {
  form.setValue('numberOfZones', Number(form.getValues().numberOfZones));
};

export const transformEditParcelData = (
  parcelFormData: EditParcelFormData,
  oldAdditionalSensorsValues: AdditionalSensorValues
): ParcelForUserEdit => {
  return {
    id: parcelFormData.id,
    name: parcelFormData.name,
    country: parcelFormData.country,
    place: parcelFormData.place,
    numberOfZones: Number(parcelFormData.numberOfZones),
    weatherForecast: parcelFormData.weatherForecast,
    additionalSensorsValues: {
      ...oldAdditionalSensorsValues,
      durationOfTheActiveValve: Number(parcelFormData.durationOfTheActiveValve),
      timeBetweenValves: Number(parcelFormData.timeBetweenValves),
    },
  };
};
