import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeviceTableSection from '@/components/layout/device/DeviceTableSection.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getDevicesByParcelId } from '@/redux/device/device.actions.ts';
import { GetDevicesForEditResponse } from '@/redux/device/device.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getSelectedParcelId } from '@/redux/parcel/parcel.selectors.ts';

const DevicesForEditPage: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const parcelId = useAppSelector(getSelectedParcelId);

  const handleErrorResponse = (response: GetDevicesForEditResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('id');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchDevices = async () => {
      // @ts-ignore
      const response = await dispatch(getDevicesByParcelId(parcelId)).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    if (!parcelId) {
      return;
    }
    fetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelId]);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <SelectedParcelHeader showNewIcon />
          <DeviceTableSection />
        </div>
      </div>
    </div>
  );
};

export default DevicesForEditPage;
