import { EditDeviceFormData } from '@/pages/EditDevicePage.tsx';
import { DeviceType } from '@/redux/device/device.types.ts';

export const createEditDeviceStartData = (): EditDeviceFormData => {
  return {
    id: '',
    name: '',
    numberOfZones: 1,
    type: DeviceType.FERT,
    color: '#ffffff',
  };
};
