import { FC } from 'react';

import PageHeader from '@/components/layout/PageHeader.tsx';
import SelectedParcelHeader from '@/components/layout/SelectedParcelHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';

const HomePage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <SelectedParcelHeader showNewIcon />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
